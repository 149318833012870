import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../components/context";
import { plainIngredient, TOKEN_NUMBER_TYPES, round, plainNumber } from "../utils";
import { PrimaryButton, WhiteButton } from "./forms/Button";
import { InputWithContext } from "./forms/Input";
import { BubblesContext, TimersContext } from "./library/context";

export const CookingContext = React.createContext({
    doneIngredients: {},
    doneInstructions: {},
    ratio: 1,
    ratioAnimation: false,
    ingredientRatioDialogOpen: false,
    selectedIngredient: null,
    setSelectedIngredient: () => { },
    selectedYield: null,
    setSelectedYield: () => { },
    isCooking: false,
    setIngredientRatioDialogOpen: () => { },
    startCooking: () => { },
    stopCooking: () => { },
    toggleIngredient: () => { },
    toggleInstruction: () => { },
    setRatio: () => { },
});

export const CookingStorage = ({ children }) => {
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [selectedYield, setSelectedYield] = useState(null);
    // const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [ingredientRatioDialogOpen, setIngredientRatioDialogOpen] = useState(false);
    const [isCooking, setIsCooking] = useState(false);
    const [doneIngredients, setDoneIngredients] = useState([]);
    const [doneInstruction, setDoneInstruction] = useState(-1);
    const [ratio, setRatio] = useState(1);
    const [ratioAnimation, setRatioAnimation] = useState(false);

    const startCooking = () => {
        // setIsCooking(true);
    }

    const stopCooking = () => {
        setIsCooking(false);
        setDoneIngredients({});
        setDoneInstruction(-1);
        setRatio(1);
        setSelectedIngredient(null);
        setSelectedYield(null);
        setIngredientRatioDialogOpen(false);
    }

    const toggleIngredient = (ingredientIndex) => {
        if (doneIngredients[ingredientIndex] !== true) {
            startCooking();
            setDoneIngredients({ ...doneIngredients, [ingredientIndex]: true });
        }
        else {
            setDoneIngredients({ ...doneIngredients, [ingredientIndex]: false });
        }
    }

    const toggleInstruction = (instructionIndex) => {
        if (doneInstruction === instructionIndex) {
            setDoneInstruction(instructionIndex - 1);
        }
        else if (doneInstruction < instructionIndex) {
            setDoneInstruction(instructionIndex);
        }
        else {
            setDoneInstruction(instructionIndex - 1);
        }

        if (doneInstruction !== -1) {
            startCooking();
        }
    }

    return (
        <CookingContext.Provider
            value={{
                doneIngredients,
                doneInstruction,
                toggleIngredient,
                toggleInstruction,
                setRatio: (r) => {
                    setRatio(r);
                    setRatioAnimation(true);
                    setTimeout(() => {
                        setRatioAnimation(false);
                    }, 400);
                },
                ratio,
                isCooking,
                startCooking,
                stopCooking,
                ingredientRatioDialogOpen,
                setIngredientRatioDialogOpen,
                selectedIngredient,
                selectedYield,
                ratioAnimation,
                setSelectedYield: (y) => { setSelectedIngredient(null); setSelectedYield(y); },
                setSelectedIngredient: (y) => { setSelectedYield(null); setSelectedIngredient(y); }
            }}
        >
            {children}
        </CookingContext.Provider>
    );
};

function getTokenValue(token) {
    let value = 1;
    if (token.type == "number.fraction") {
        value = token.value[0];
    }
    else if (token.type == "range") {
        value = (token.value[0].value[0] + token.value[1].value[0]) / 2;
    }
    else if (token.type == "number.text") {
        value = token.value[0];
    }
    else if (token.type == "amount") {
        value = token.value[0].value[0];
    }
    else {
        value = token.value[0];
    }
    return value;
}

export function IngredientRatioDialog({ id, open, setOpen }) {

    const cancelButtonRef = useRef(null)
    const [mostLikelyUnit, setMostLikelyUnit] = useState("");
    const [oldAmount, setOldAmount] = useState(1);
    const [newAmount, setNewAmount] = useState(1);
    const [rangeHelp, setRangeHelp] = useState(false);
    const context = useContext(CookingContext);
    const ratio = newAmount / oldAmount;

    useEffect(() => {
        if (context.selectedIngredient) {
            const numbers = context.selectedIngredient.quantity_tokens.filter(t => TOKEN_NUMBER_TYPES.includes(t.type));
            if (numbers.length == 0) {
                setNewAmount(round(1 * ratio));
                setOldAmount(1);
            }
            else {
                // There might be more than one number. If that is the case we just allow to scale based on the first one
                // and hope the second one is just an addition.
                const value = getTokenValue(numbers[0]);
                setRangeHelp(numbers[0].type === "range");
                setNewAmount(round(value * ratio) || 1);
                setOldAmount(value || 1);

                if (numbers[0].type === "amount") {
                    setMostLikelyUnit(numbers[0].value[1]["value"][0]);
                }

            }
        }
        else if (context.selectedYield) {
            let value = 1;
            if (context.selectedYield[0] > -1) {
                // When the value is -1 we are increaasing the entire yield
                let token = context.selectedYield[1][context.selectedYield[0]];
                setRangeHelp(token.type === "range");
                value = getTokenValue(token);
                if (token.type === "amount") {
                    setMostLikelyUnit(token.value[1]["value"][0]);
                }
                else {
                    // Get a bit creative trying to determine the unit
                    const options = [1, -1, 2, -2, 3, -3].map(i => context.selectedYield[1][context.selectedYield[0] + i]).filter(o => o !== undefined);
                    const viableOptions = options.filter(
                        o => o.type === "unit" || [
                            "portion", "portions", "serving", "servings", "serves"
                        ].includes(o.text.trim().toLowerCase())
                    );
                    setMostLikelyUnit(viableOptions.length > 0 ? (
                        { 'serves': 'servings' }[viableOptions[0].text.trim().toLowerCase()] || viableOptions[0].text
                    ) : null);
                }
            }
            setNewAmount(round(value * ratio));
            setOldAmount(value);
        }
        else {
            setNewAmount(1);
            setOldAmount(1);
        }
    }, [context.selectedIngredient, context.selectedYield, ratio])

    const confirm = () => {
        context.startCooking();
        context.setRatio(ratio);
        setOpen(false);
    }

    const onChange = (amount) => {
        let index = 0
        setNewAmount(
            amount
                .toString()
                .replace(/,/g, ".")
                .replace(/\./g, (item) => (!index++ ? item : ""))
                .replace(/[^0-9\.]/g, "")
        );
    }

    const onClose = () => {
        setNewAmount(oldAmount);
        setOpen(false);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-30 inset-0 overflow-y-auto print:hidden" initialFocus={cancelButtonRef} onClose={onClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-10"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-8"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

                                <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                                    {context.selectedIngredient ? "Scale Ingredient" : "Scale yield"}
                                </Dialog.Title>

                                <div className="mt-1">
                                    <p className="text-sm text-gray-500">
                                        {context.selectedIngredient ? (
                                            <>Scale this recipe based on the required amount of this ingredient</>
                                        ) : (
                                            <>Scale this recipe based on the output it produces</>
                                        )}
                                    </p>
                                </div>

                                <div className="flex justify-start gap-2 mt-4">
                                    <InputWithContext inputMode="decimal" position="right" className="flex-1" value={newAmount} context={<>
                                        <span className="hidden sm:inline">{mostLikelyUnit}</span> <span className="">{rangeHelp && "(aprox)"}</span>
                                    </>} onChange={(e) => onChange(e.target.value)} />
                                    <button className="flex-none border border-gray-200 rounded-md px-4 py-2" onClick={() => onChange(round(newAmount * 0.5))}>1/2</button>
                                    <button className="flex-none border border-gray-200 rounded-md px-4 py-2" onClick={() => onChange(round(newAmount * 2))}>2x</button>
                                    <button className="flex-none border border-gray-200 rounded-md px-4 py-2" onClick={() => onChange(round(newAmount * 3))}>3x</button>
                                </div>
                                <div className="text-sm text-gray-500 pt-2 px-1">
                                    {context.selectedIngredient && (
                                        <>
                                            {context.selectedIngredient?.name}, <RecipeTokens id={`${id}-ingredient-ratio`} tokens={context.selectedIngredient.quantity_tokens} fixedRatio={ratio} interactive={false} />
                                        </>
                                    )}
                                    {context.selectedYield && (
                                        <RecipeTokens id={`${id}-yield-ratio`} tokens={context.selectedYield[1]} fixedRatio={ratio} interactive={false} />
                                    )}
                                </div>

                            </div>
                            <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse gap-2">
                                <PrimaryButton onClick={confirm}>
                                    Confirm
                                </PrimaryButton>
                                <WhiteButton onClick={onClose} ref={cancelButtonRef}>
                                    Close
                                </WhiteButton>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}


const Throughable = ({ children, status, toggle, hint, className, throughClassName, hintClassname }) => {
    return (
        <div
            onClick={toggle}
            className={`group relative cursor-pointer transition-opacity ease-out duration-500 ${status ? throughClassName : className}`}
        >
            {hint && (
                <div className={`absolute -left-6 top-1 ${hintClassname}`}>
                    <CheckIcon className={`${status ? "text-gray-900" : "text-transparent group-hover:text-gray-900"} print:opacity-0 w-5 h-5`} />
                </div>
            )}
            {children}
        </div >
    );
};

Throughable.defaultProps = {
    toggle: () => { },
    hint: false,
    throughClassName: "line-through opacity-30 print:opacity-100 print:no-underline",
    className: "",
    hintClassname: "",
};

const Fact = ({ name, value }) => (
    <div className="lg:flex mb-3 lg:mb-2">
        <div className="min-w-[180px] font-semibold capitalize">
            {name}
        </div>
        <div className="grow mb-2 md:mb-0">
            {value}
        </div>
    </div>
)

export const RecipeTokens = ({ id, tokens, isFull, onClick, fixedRatio, interactive }) => {
    const context = useContext(CookingContext);
    const ratio = fixedRatio || context.ratio;
    const hasNumbers = tokens.filter(t => TOKEN_NUMBER_TYPES.includes(t.type)).length > 0;
    isFull = isFull || !hasNumbers;
    const fullOnclick = () => {
        onClick(-1, tokens);
    }
    const ratioException = tokens.map(t => t.text).join('').match(/to taste/i) !== null;

    return (
        <span onClick={(isFull && interactive) ? fullOnclick : undefined} className={(isFull && interactive) ? `recipe-token transition-colors ease-out duration-200 border-b-2 hover:border-gray-500 print:border-transparent pb-1 cursor-pointer ${context.ratioAnimation ? "border-gray-400" : "border-gray-200"}` : undefined}>
            {ratioException === false && hasNumbers === false && ratio !== 1 && (
                <span>{round(ratio)}<span className="text-sm mr-1">✕</span>&nbsp;</span>
            )}
            {tokens && tokens.map((t, i) => {
                if (!TOKEN_NUMBER_TYPES.includes(t.type)) {
                    return t.text;
                }
                let value = plainNumber(t, ratio);
                return <span
                    key={`tokens-${id}-i`}
                    onClick={interactive ? (() => {
                        onClick(i, tokens);
                    }) : undefined}

                    className={
                        (!isFull && interactive) ? `recipe-token transition-colors ease-out duration-200 border-b-2 hover:border-gray-500 pb-1 print:border-transparent cursor-pointer ${context.ratioAnimation ? "border-gray-400" : "border-gray-200"}` : undefined
                    }>
                    {ratio === 1 ? t.text : value}
                </span>
            })}
        </span>
    );
}

RecipeTokens.defaultProps = {
    isFull: false,
    fixedRatio: null,
    interactive: true
};

export const InstructionTokens = ({ id, tokens, onClick }) => {
    return (
        <>
            {tokens && tokens.map((t, i) => {
                if (t.type === "text") {
                    return <span key={`tokens-${id}-${i}`}>{t.text}</span>;
                }
                return (
                    <span
                        key={`tokens-${id}-${i}`}
                        className={
                            `instruction-token transition-colors ease-out duration-200 border-b-2 hover:border-gray-500 pb-1 print:border-transparent cursor-pointer border-gray-200"`
                        }
                        onClick={(e) => onClick({ token: t })}
                    >
                        {t.text}
                    </span>
                );
            })}
        </>
    );
}

InstructionTokens.defaultProps = {};


const OutputFact = ({ id, name, tokens }) => {
    const context = useContext(CookingContext);

    return (
        <Fact name={name} key={`${id}-output`} value={<RecipeTokens id={`${id}-tokens`} tokens={tokens} onClick={(i, tokens) => {
            context.setSelectedYield([i, tokens]);
            context.setIngredientRatioDialogOpen(true);
        }} />} />
    );
}

const Recipe = ({ recipe }) => {

    const context = useContext(CookingContext);
    const timersContext = useContext(TimersContext);
    const _gc = useContext(GlobalContext);
    const router = useRouter();

    const elementInstructions = recipe.elements.map(
        (e) => e.instructions.length
    );
    const elementsInstructionsStart = elementInstructions.map((e, i) =>
        elementInstructions.slice(0, i).reduce((acc, val) => acc + val, 0)
    );

    return (
        <div className="space-y-6">

            <IngredientRatioDialog id={`recipe-${recipe.id}-ratio-dialog`} open={context.ingredientRatioDialogOpen} setOpen={context.setIngredientRatioDialogOpen} />

            <div>
                <h2 className="text-lg font-bold text-gray-900 uppercase antialiased mb-2 md:mb-2">
                    {recipe.profile.name}
                </h2>

                <h1
                    className="text-4xl sm:text-5xl tracking-tight -ml-1 font-extrabold text-gray-900 antialiased flex-inline"
                    style={{ lineHeight: "1.1" }}
                >
                    <a>{recipe.title}</a>
                </h1>
            </div>

            <dl className="text-lg">
                {recipe.facts.output_tokens && <OutputFact id={`recipe-${recipe.id}-output`} name="Yield" tokens={recipe.facts.output_tokens} />}
                {recipe.facts.time && <Fact name="Time estimate" value={recipe.facts.time} />}
                {recipe.facts.challenge && <Fact name="Most Challenging" value={recipe.facts.challenge} />}
                {recipe.facts.equipment && <Fact name="Equipment" value={recipe.facts.equipment} />}
                {recipe.facts.source && <Fact name="Source" value={recipe.facts.source} />}
                {recipe.facts.storage && <Fact name="Storage" value={recipe.facts.storage} />}
                {recipe.facts.caution && <Fact name="Caution" value={recipe.facts.caution} />}
            </dl>

            <div className="md:leading-relaxed text-lg text-gray-900 cap-first" style={{ maxWidth: '75ch' }}>
                {recipe.description}
            </div>

            <div className="text-lg text-gray-900">
                {recipe.elements.map((element, k) => {
                    const elementDone = (k * 1000) + element.instructions.length - 1 <= context.doneInstruction;
                    return (
                        <React.Fragment key={`element-${k}`}>

                            <div className={`${k > 0 && "mt-6 no-break"} sm:grid grid-cols-12 gap-4 border-gray-200 border-t pt-6 transition-opacity ease-linear duration-1000 ${elementDone && 'opacity-50 print:opacity-100'}`}>
                                {element.name && (<div className="font-semibold col-span-12">{element.name}</div>)}
                                <div className={`col-span-5 md:col-span-5 pr-4`}>
                                    <div className="space-y-2">
                                        {element.ingredients.map(
                                            (ingredient, i) => {
                                                const doneIngredient = context.doneIngredients[k * 1000 + i];
                                                return (
                                                    <div key={`ingredient-${i}`} className="flex no-break">
                                                        <div className="w-4/6 pt-1 pr-1 print:pt-0 leading-normal md:leading-relaxed">
                                                            <Throughable hintClassname="hidden lg:block" className={!doneIngredient && elementDone && "cap-first opacity-30 print:opacity-100"} hint={true} status={doneIngredient} toggle={() => context.toggleIngredient(k * 1000 + i)}>{ingredient.name}</Throughable>
                                                        </div>
                                                        <div className="w-2/6 text-right">
                                                            <div className="flex items-start">
                                                                <div title={plainIngredient(ingredient, context.ratio)} className="text-right w-full relative top-1 leading-normal no-break">
                                                                    <Throughable className={!doneIngredient && elementDone && "opacity-30 print:opacity-100"} status={context.doneIngredients[k * 1000 + i] === true}>
                                                                        <RecipeTokens id={`recipe-${recipe.id}-ingredient`} tokens={ingredient.quantity_tokens} isFull={true} onClick={() => {
                                                                            context.setSelectedIngredient(ingredient);
                                                                            context.setIngredientRatioDialogOpen(true);
                                                                        }} />
                                                                    </Throughable>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                                <div className="col-span-7 md:col-span-7 pt-6 sm:pt-1">
                                    {/* <div className="col-span-12 align-text-top text-lg pl-1 pb-4 italic">{element.name}</div> */}
                                    <ol className="list-none list-inside space-y-4 xl:space-y-5 block">
                                        {element.instructions.map(
                                            (instruction, i) => {
                                                const done = context.doneInstruction >= (k * 1000 + i);
                                                return (
                                                    <li
                                                        className="group flex items-start justify-start align-top no-break cursor-pointer"
                                                        key={`instruction-${i}`}
                                                        onClick={(e) => {
                                                            if (!e.target.classList.contains('instruction-token')) {
                                                                context.toggleInstruction(k * 1000 + i);
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            className={`force-pring-backgrounds group inline-flex justify-center items-center text-xs font-semibold align-middle text-white w-5 h-5 rounded-full mr-4 relative ease-out transition-opacity duration-500 ${done ? 'opacity-30 print:opacity-100 transition-opacity bg-white print:bg-gray-900' : 'bg-gray-900 group-hover:bg-white'}`}
                                                            style={{ marginTop: "1px" }}
                                                        >
                                                            <span className={`absolute ${done ? "opacity-0 print:opacity-100" : "group-hover:opacity-0 print:opacity-100"}`}>{elementsInstructionsStart[k] + i + 1}</span>
                                                            <span className={`absolute text-gray-900 ${done ? "opacity-100" : "opacity-0 group-hover:opacity-100"} `}>
                                                                <CheckIcon className="w-5 h-5 print:opacity-0" />
                                                            </span>
                                                        </div>
                                                        <div className="flex-1 -mt-1 md:leading-relaxed cap-first">
                                                            <Throughable status={done}>
                                                                <InstructionTokens onClick={({ token }) => {
                                                                    timersContext.addTimerFromTimerToken({ token: token, recipeId: recipe.id });
                                                                }} id={`recipe-${recipe.id}-instruction`} tokens={instruction?.value_tokens || []} />
                                                            </Throughable>
                                                        </div>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ol>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}

            </div>
        </div>
    );
};

Recipe.defaultProps = {
    showProfile: true,
    startCooking: () => { }
};

export default Recipe;
