import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { BubblesContext } from "./library/context";
import { XMarkIcon } from '@heroicons/react/24/outline';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { currentTime, humanizeDuration } from "../utils";
import NoSleep from 'nosleep.js';
import { useRouter } from 'next/router';


export const Bubble = ({ children, visibleIn, goneIn, hiddenIn }) => {

    const [visible, setVisible] = useState(false);
    const [gone, setGone] = useState(false);
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        const t = setTimeout(() => {
            setVisible(true);
        }, visibleIn);
        return () => clearTimeout(t);
    }, []);

    useEffect(() => {
        const t = setTimeout(() => {
            setGone(true);
        }, goneIn);
        return () => clearTimeout(t);
    }, []);

    useEffect(() => {
        const t = setTimeout(() => {
            setHidden(true);
        }, hiddenIn);
        return () => clearTimeout(t);
    }, []);

    const classess = visible ? (gone ? "bottom-40 opacity-0 duration-1000" : "duration-200 bottom-0") : "duration-200 -bottom-32";

    return (
        <div className={`group transition-all ease-in-out relative ${classess} ${hidden && "hidden"}`}>
            {children}
        </div>
    );
};

Bubble.defaultProps = { index: 0, visibleIn: 50, goneIn: 3000, hiddenIn: 4000 };


export const Timer = ({ id, children }) => {
    const context = useContext(BubblesContext);
    const router = useRouter();
    const [visible, setVisible] = useState(true);
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [recipePath, setRecipePath] = useState("");
    const [recipeId, setRecipeId] = useState(null);
    const [duration, setDuration] = useState(null);
    const [alerts, setAlerts] = useState([]);
    const [fullDuration, setFullDuration] = useState(0);
    const [target, setTarget] = useState(null);
    const [clock, setClock] = useState(0);
    const noSleep = useMemo(() => {
        const ns = new NoSleep();
        return ns;
    }, []);
    const audioRef = useRef(null);
    const alertRef = useRef(null);

    const active = target !== null && target > 0;
    const remaining = active ? Math.max(target - currentTime(), 0) : duration;
    const elapsed = fullDuration - remaining;
    const ratio = Math.min(100 / fullDuration * elapsed, 100);
    const remainingHours = Math.floor(remaining / 60 / 60);
    const remainingMinutes = Math.floor((remaining - (remainingHours * 60 * 60)) / 60);
    const remainingSeconds = remaining - (remainingHours * 60 * 60) - (remainingMinutes * 60);

    const action = (event) => {
        if (remaining === 0) {
            setTarget(null);
            setDuration(fullDuration);
            noSleep.disable();
        }
        else if (active) {
            setTarget(null);
            setDuration(remaining);
            noSleep.disable();
        }
        else {
            setTarget(currentTime() + duration);
            setDuration(null);
            noSleep.enable();
        }
    }

    const actionName = (() => {
        if (remaining === 0) {
            return "reset";
        }
        else if (active) {
            return "stop";
        }
        else {
            return "start";
        }
    })();

    const deleteAction = () => {
        setTarget(-1);
        localStorage.removeItem(`timer_${id}`);
    }

    useEffect(() => {
        if (remaining === 0) {
            if (audioRef.current !== null) {
                audioRef.current.play();
            }
        }
        else {
            audioRef.current?.pause();
        }

        if (alerts.includes(elapsed)) {
            alertRef.current.play();
        }

    }, [clock]);

    useEffect(() => {
        const initial = JSON.parse(localStorage.getItem(`timer_${id}`));
        if (initial && initial.target && (initial.target + 3 * 60 * 60 < currentTime())) {
            // if more than twelve hours have pass since this timer triggered mark
            // this timer for removal
            setTarget(-1);
            localStorage.removeItem(`timer_${id}`);
        }
        else if (initial) {
            setVisible(initial.recipeId === context.activeRecipeId);
            setDuration(initial.duration);
            setTarget(initial.target);
            setFullDuration(initial.fullDuration);
            setText(initial.text);
            setRecipePath(initial.recipePath);
            setRecipeId(initial.recipeId);
            setTitle(initial.title);
            setAlerts(initial.alerts || []);
        }
    }, [id, router, context.activeRecipeId]);

    useEffect(() => {
        if (target !== -1) {
            localStorage.setItem(`timer_${id}`, JSON.stringify({
                id: id,
                duration: duration,
                target: target,
                text: text,
                fullDuration: fullDuration,
                recipePath: recipePath,
                recipeId: recipeId,
                title: title,
                alerts: alerts
            }));
        }
        let interval = null;
        if (target !== null) {
            interval = setInterval(() => {
                setClock(Date.now());
            }, 1000);
        }
        else {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [duration, target]);

    if (target === -1) {
        return null;
    }

    return (<>
        <audio ref={alertRef}>
            <source src="/alert.mp3" type="audio/mpeg" />
        </audio>
        <audio ref={audioRef}>
            <source src="/alert.mp3" type="audio/mpeg" />
        </audio>
        {(actionName === "reset" || visible) && children({ id, ratio, remaining, remainingHours, remainingMinutes, remainingSeconds, text, actionName, action, alerts, fullDuration, title, visible, deleteAction })}
    </>)
}


export const TimerBubble = ({ timerId }) => {

    const [help, setHelp] = useState(false);
    return (
        <Timer id={timerId}>
            {({ id, ratio, remaining, remainingHours, remainingMinutes, remainingSeconds, text, actionName, action, alerts, fullDuration, title, deleteAction, visible }) => {
                const style = { "--timer-ratio": `${ratio}%` };
                return (
                    <Bubble key={id} goneIn={10000000} hiddenIn={11000000}>
                        <div style={style} className={`timer-progress from-gray-800 to-gray-900 relative min-w-xxs max-w-xxs px-0 pt-4 shadow-lg  text-white rounded-lg border-gray-200 border ${remaining === 0 && "ring-1 ring-gray-900 ring-offset-transparent"} `}>
                            {/* timer: {b.token.value[0].value[0].value[0]} {b.token.value[1]} */}
                            <div className='flex items-center justify-end gap-4 z-10 relative px-6 pb-3'>
                                <div className="">
                                    <div className='text-lg font-mono font-normal text-right'>{remainingHours.toString().padStart(2, "0")}:{remainingMinutes.toString().padStart(2, "0")}:{remainingSeconds.toString().padStart(2, "0")}</div>
                                    <div className='text-xs line-clamp-1 text-right'>
                                        {text}
                                        {alerts && alerts.length > 0 && (
                                            <button className="hover:cursor-pointer hover:scale-110 active:scale-125" onClick={() => { setHelp(h => !h) }}>
                                                <QuestionMarkCircleIcon className='ml-1 inline-block w-4 h-4 opacity-90' />
                                            </button>
                                        )}

                                    </div>
                                </div>
                                <button className="hover:cursor-pointer hover:scale-110 active:scale-125" onClick={action}>
                                    {
                                        {
                                            "reset": (
                                                <svg className="animate-wiggle " width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                                </svg>
                                            ),
                                            "start": (
                                                <svg className="text-white" width="20" height="20" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                                </svg>
                                            ),
                                            "stop": (
                                                <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" >
                                                    <rect x="6" y="4" width="4" height="16"></rect><rect x="14" y="4" width="4" height="16"></rect>
                                                </svg>
                                            )
                                        }[actionName]
                                    }
                                </button>
                            </div>
                            <div className={`min-w-xxs max-w-xxs text-xs rounded-b-md px-6 mt-1 bg-gray-700 transition-all duration-500 ease-in-out overflow-hidden ${help && " pb-4 pt-3"}`} style={{ maxHeight: help ? "300px" : "0px" }}>
                                {alerts && alerts.length == 1 && (
                                    <>
                                        This alarm will ring both in {humanizeDuration(alerts[0])} and {humanizeDuration(fullDuration)}.
                                    </>
                                )}
                                {alerts && alerts.length > 1 && (
                                    <>
                                        This alarm will ring after {humanizeDuration(alerts[0])} and then every {humanizeDuration(alerts[1] - alerts[0])} until {humanizeDuration(fullDuration)} pass
                                    </>
                                )}

                            </div>
                        </div>

                        <div className='absolute -top-2 -left-2 md:hidden group-hover:block hover:block w-6 pr-3 pt-1 pb-2'>
                            <button onClick={deleteAction} className='hover:cursor-pointer hover:scale-110 active:scale-125 bg-gray-600 rounded-full w-6 h-6  shadow-lg flex items-center justify-center'>
                                <XMarkIcon className="w-4 h-4 text-white" />
                            </button>
                        </div>
                    </Bubble >
                );
            }
            }
        </Timer >
    );
}

export const BubblesDisplay = () => {

    const bubblescontext = useContext(BubblesContext);
    return (
        <div className='fixed bottom-4 right-4 z-30'>
            <div className='flex flex-col gap-1 items-end'>
                {bubblescontext.bubbles.map(b => {
                    if (b.type === "message") {
                        return (
                            <Bubble key={b.id}>
                                <div className={`max-w-sm line-clamp-2 font-semibold text-sm px-8 py-4 shadow-gray-200 shadow-lg bg-gray-900 text-white rounded-full border-gray-200 border`}>
                                    {b.message}
                                </div>
                            </Bubble>
                        );
                    }
                    else if (b.type === "timer") {
                        return (
                            <TimerBubble key={b.id} timerId={b.timerId} />
                        );
                    }
                })}
            </div>
        </div >
    );
}
