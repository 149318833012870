const special = [
    "zeroth",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth",
];
const deca = [
    "twent",
    "thirt",
    "fort",
    "fift",
    "sixt",
    "sevent",
    "eight",
    "ninet",
];

export function stringifyNumber(n) {
    if (n < 20) return special[n];
    if (n % 10 === 0) return `${deca[Math.floor(n / 10) - 2]}ieth`;
    return `${deca[Math.floor(n / 10) - 2]}y-${special[n % 10]}`;
}

export function slugify(str) {
    const separator = "-";
    return str
        .toString()
        .normalize("NFD") // split an accented letter in the base letter and the acent
        .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
        .toLowerCase()
        .replace(/\s+/g, separator)
        .replace(/[^a-z0-9-]/g, separator) // remove all chars not letters, numbers and spaces (to be replaced)
        .replace(/-+/g, separator);
}

export function hex(buffer) {
    const hexCodes = [];
    const view = new DataView(buffer);
    for (let i = 0; i < view.byteLength; i += 4) {
        // Using getUint32 reduces the number of iterations needed (we process 4 bytes each time)
        const value = view.getUint32(i);
        // toString(16) will give the hex representation of the number without padding
        const stringValue = value.toString(16);
        // We use concatenation and slice for padding
        const padding = "00000000";
        const paddedValue = (padding + stringValue).slice(-padding.length);
        hexCodes.push(paddedValue);
    }
    // Join all the hex strings into one
    return hexCodes.join("");
}


export function round(number, unit) {
    let value = Math.round((number + Number.EPSILON) * 100) / 100;
    if (unit === undefined) {
        return value;
    }
    switch (unit) {
        case "range":
        case "g":
        case "mg":
        case "ml":
            if (value > 100) {
                value = Math.round(value);
            }
            break;
        default:
            break;
    }
    return value;
}


export const TOKEN_NUMBER_TYPES = ["number", "number.text", "number.fraction", "range", "amount"];

export const plainNumber = (t, ratio) => {
    let value = 0;
    if (t.type == "number") value = round(t.value[0] * ratio);
    if (t.type == "number.text") value = round(t.value[0] * ratio);
    if (t.type == "number.fraction") value = round(t.value[0] * ratio);
    if (t.type == "range") value = `${round(t.value[0].value[0] * ratio, 'range')}-${round(t.value[1].value[0] * ratio, 'range')}`;
    if (t.type == "amount") value = `${round(t.value[0].value[0] * ratio)} ${t.value[1]["text"]}`;
    return value;
}
export const plainIngredient = (ingredient, ratio) => {

    // amount  [ complexnumber , unit]
    // text
    // sep
    // range [ complexnumber, complexnumber]
    // timer  [ complexnumber, unit]
    // temperature [number, unit]
    // number.text
    // number
    // number.fraction

    // complexnumber = number.text | number | number.fraction


    ratio = ratio || 1;
    const tokens = ingredient.quantity_tokens;
    const hasNumbers = tokens.filter(t => TOKEN_NUMBER_TYPES.includes(t.type)).length > 0;
    let parts = tokens.map(t => {
        if (!TOKEN_NUMBER_TYPES.includes(t.type)) {
            return t.text;
        }
        let value = plainNumber(t, ratio);
        return ratio === 1 ? t.text : value;
    });
    const ratioException = parts.join('').match(/to taste/i) !== null;
    if (ratioException === false && hasNumbers === false && ratio !== 1) {
        parts = [`${round(ratio)}✕ `, ...parts];
    }
    return parts.join('');
}

export const humanizeDuration = (duration) => {
    if (duration > 60 * 60) {
        return `${Math.round(duration / 60 / 60)} hours`;
    }
    else if (duration > 60) {
        return `${Math.round(duration / 60)} minutes`;
    }
    return `${duration} seconds`;
}


export const currentTime = () => {
    return Math.round(Date.now() / 1000);
}
